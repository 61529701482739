import React, { useState, useRef, useEffect } from 'react';
import css from './CityLinksMenu.module.css';

const CityLinksMenu = ({ links, intl }) => {

    const City =
        intl.formatMessage({ id: 'TopbarDesktop.LinksMenu.City' });

    const [isVisible, setIsVisible] = useState(false);
    const menuRef = useRef(null);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={css.cityWrapper} ref={menuRef}>
            <a className={css.cityLink} onClick={toggleVisibility}>
                <span className={css.cityLinkLabel}>{City}</span>
            </a>
            {isVisible && (
                <div className={css.dropdownLink}>
                    {links.map((link, index) => (
                        <div className={css.content} key={index}>
                            <a href={link.href}>
                                {link.text}
                            </a>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CityLinksMenu;
