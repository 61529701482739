import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRouteConfiguration } from '../../../../../context/routeConfigurationContext';
import css from './ServicesDropDown.module.css';
import { createResourceLocatorString } from '../../../../../util/routes';

// The ServicesDropDown component
const ServicesDropDown = ({ requiredDataFormat, queryParams, intl }) => {

    const ServiceHeading = intl.formatMessage({ id: 'TopbarDesktop.LinksMenu.Services' });


    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null); // Ref for the dropdown menu

    const history = useHistory();
    const routeConfiguration = useRouteConfiguration();

    // Function to handle option selection
    const handleSelect = (option,category) => {
        setIsOpen(false);
        if (queryParams) {
            queryParams(option,category); // Call the provided queryParams function with the selected option
        }
    };

    // Function to handle query parameters
    function queryParams(option,category) {
        history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, { [`pub_${category}`]: option }));
    }

    // Function to toggle dropdown visibility
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    // Close the dropdown menu if clicked outside
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        // Add event listener for clicks outside the component
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Clean up the event listener on component unmount
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={css.dropdowncontainer} ref={dropdownRef}>
            <a className={css.serviceLink} onClick={toggleDropdown}>
                <span className={css.serviceLinkLabel}>{ServiceHeading}</span>
            </a>
            {isOpen && (
                <ul className={css.megadropDown}>
                    {requiredDataFormat.map((category, index) => (
                        <li key={index} className={css.content}>
                            <h3>{category.mainCategory?.label}</h3>
                            {category.items.map((item, idx) => (
                                <div
                                    key={idx}
                                    className={css.items}
                                    onClick={() => handleSelect(item.option,category.mainCategory?.key)}
                                >
                                    {item.label}
                                </div>
                            ))}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ServicesDropDown;
